import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { PremoComponent } from '@view';
import { RouterModule } from '@angular/router';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

@NgModule({
  declarations: [PremoComponent],
  exports: [PremoComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        TranslateModule,
        MatTooltipModule,
        MatInputModule,
        RouterModule,
        MatMenuModule,]
})
export class PremoModule {}
