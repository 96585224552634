import { Component } from '@angular/core';

@Component({
  selector: 'premo-fitted-loader',
  templateUrl: './fitted-loader.component.html',
  styleUrls: ['./fitted-loader.component.scss'],
})
export class FittedLoaderComponent {
  constructor() {}

}
