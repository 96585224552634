import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Site } from '@model';
import { GoogleService, TreeService, SiteService } from '@service';

@Component({
  selector: 'premo-geo',
  templateUrl: './geo.component.html',
  styleUrls: ['./geo.component.scss'],
})
export class GeoComponent implements OnInit, OnDestroy {

  sites: Site[];
  apiKey: string;
  private subscriptions: Subscription[] = [];
  geojson_url_blob: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private treeService: TreeService,
    private googleService: GoogleService,
    private siteService: SiteService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.siteService.getAllSites$().subscribe(sites => {
        this.sites = sites;
      }),
    );
    this.apiKey = this.googleService.apiKey;

    this.siteService.getSitesCurrentForecastStatus$().subscribe((response: any) =>{
      const blob = new Blob([JSON.stringify(response.data)], {type: "application/json"});
      this.geojson_url_blob = URL.createObjectURL(blob);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
