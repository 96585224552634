import {Component, ElementRef, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ChartService} from 'src/app/core/service/chart.service';
import {filter, map} from 'rxjs';
import {Chart, ChartItem, registerables} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-date-fns';
import {DeviceService, ResourceService} from "@service";
import {Device} from "@model";

Chart.register(...registerables);
Chart.register(zoomPlugin);

@Component({
  selector: 'premo-chart-view',
  templateUrl: './chart-view.component.html',
  styleUrls: ['./chart-view.component.css'],
})
export class ChartViewComponent implements OnInit {

  public base_url = ''; //environment.base_url;
  dataPoints1:any = [];
  dataPoints2:any = [];
  dataPoints3:any = [];
  dataPoints4:any = [];

  dataPoints1_temp:any = [];
  dataPoints2_temp:any = [];
  dataPoints3_temp:any = [];
  dataPoints4_temp:any = [];

  dataPoints1_dni:any = [];
  dataPoints2_dni:any = [];
  dataPoints3_dni:any = [];
  dataPoints4_dni:any = [];

  dataPoints1_precipitationRate:any = [];
  dataPoints2_precipitationRate:any = [];
  dataPoints3_precipitationRate:any = [];
  dataPoints4_precipitationRate:any = [];

  dataPoints1_cloudCover:any = [];
  dataPoints2_cloudCover:any = [];
  dataPoints3_cloudCover:any = [];
  dataPoints4_cloudCover:any = [];

  chart:any;

  constructor(
      private chartService: ChartService,
      private http : HttpClient,
      private elementRef: ElementRef,
      private resourceService: ResourceService<Device>,
      private deviceService: DeviceService
  ) {
    this.base_url = window.location.origin + resourceService.apiLocation;
  }

  ngOnInit(): void {
    let ctx = document.getElementById('premo-chart') as ChartItem;
    let data = [];
    let chart = new Chart(ctx,
        {
          type: 'line',
          data: {
            labels: data.map(row => row.datetime),
            datasets: [
              {
                label: 'Forecasted Data',
                data: data.map(row => row.demand),
              }
            ]
          },
          options: {
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: 'Forecast Data'
              },
              subtitle: {
                display: true,
                text: 'Select Device ID from Dropdown Menu.'
              },
              zoom: {
                pan: {
                  enabled: true,
                  mode: 'x'
                },
                zoom: {
                  wheel: {
                    enabled: true
                  },
                  pinch: {
                    enabled: true
                  },
                  drag: {
                    enabled: true,
                    modifierKey: 'shift'
                  },
                  mode: 'x',
                }
              },
            },
            scales: {
              x: {
                type: 'time',
                time: {
                  displayFormats: {
                    hour: 'HH:mm',
                    minute: 'HH:mm',
                    second: 'HH:mm:ss'
                  }
                },
                position: 'bottom',
                title: {
                  display: true,
                  text: 'Date'
                },
                ticks: {
                  autoSkip: true,
                  autoSkipPadding: 50,
                  maxRotation: 0,
                  major: {
                    enabled: true
                  },
                }
              },
              y: {
                position: 'left',
                title: {
                  display: true,
                  text: 'Demand (kW)'
                },
                type: 'linear',
                ticks: {
                  callback: (val, index, ticks) => index === 0 || index === ticks.length - 1 ? null : val,
                }
              },
              y2: {
                position: 'right',
                title: {
                  display: true,
                  text: 'FeelsLike Temperature (°C)'
                },
                reverse: false,
                ticks: {
                  color: 'orange',
                  callback: (val, index, ticks) => index === 0 || index === ticks.length - 1 ? null : val,
                },
                grid: {
                  drawOnChartArea: false // only want the grid lines for one axis to show up
                }
              },
              y3: {
                position: 'right',
                title: {
                  display: true,
                  text: 'Direct Normal Irradiance (W/m2)'
                },
                reverse: false,
                ticks: {
                  color: 'crimson',
                  callback: (val, index, ticks) => index === 0 || index === ticks.length - 1 ? null : val,
                },
                grid: {
                  drawOnChartArea: false // only want the grid lines for one axis to show up
                }
              },
              y4: {
                position: 'right',
                title: {
                  display: true,
                  text: 'Cloud Cover (%)'
                },
                reverse: false,
                ticks: {
                  color: 'darkkhaki',
                  callback: (val, index, ticks) => index === 0 || index === ticks.length - 1 ? null : val,
                },
                grid: {
                  drawOnChartArea: false // only want the grid lines for one axis to show up
                }
              },
              y5: {
                position: 'right',
                title: {
                  display: true,
                  text: 'Precipitation Rate (%)'
                },
                reverse: false,
                ticks: {
                  color: 'blue',
                  callback: (val, index, ticks) => index === 0 || index === ticks.length - 1 ? null : val,
                },
                grid: {
                  drawOnChartArea: false // only want the grid lines for one axis to show up
                }
              }
            }
          }
        }
    );
    this.chart = chart;
    this.chartService.deviceId$.pipe(
        filter(d => d !== null),
        map(d => d as string)
    ).subscribe(deviceId => {
      this.updateChart(deviceId);
    })
  }

  // getChartJSONdata_async(url: string): Promise<string> {
  //   const request$ = this.http.get(url).pipe(
  //       map((response: any) => {
  //         const blob = new Blob([JSON.stringify(response.data)], {type: "application/json"});
  //         return URL.createObjectURL(blob);
  //       })
  //   );
  //   return lastValueFrom(request$);
  // };

  prepDataset(data: any, label: string, lineColor: string, yAxisID: string): any {
    let dataset = {
      label: label,
      data: data,
      borderColor: lineColor,
      backgroundColor: lineColor,
      pointBorderColor: lineColor,
      pointBackgroundColor: lineColor,
      pointBorderWidth: 2,
      pointRadius: 2,
      pointHoverRadius: 4,
      lineWidth: 1.5,
      borderWidth: 1.5,
      borderDash: [],
      yAxisID: yAxisID,
    }
    return dataset;
  }

  async updateChart(deviceId: string): Promise<any>{
    console.log(deviceId);
    this.dataPoints1 = [];
    this.dataPoints2 = [];
    this.dataPoints3 = [];
    this.dataPoints4 = [];
    this.dataPoints1_temp = [];
    this.dataPoints2_temp = [];
    this.dataPoints3_temp = [];
    this.dataPoints1_dni = [];
    this.dataPoints2_dni = [];
    this.dataPoints3_dni = [];
    this.dataPoints1_precipitationRate = [];
    this.dataPoints2_precipitationRate = [];
    this.dataPoints3_precipitationRate = [];
    this.dataPoints1_cloudCover = [];
    this.dataPoints2_cloudCover = [];
    this.dataPoints3_cloudCover = [];
    this.chart.data.datasets = [];

    if (deviceId == ''){
      console.log("DeviceId is empty.. Clearing chart.");
      this.chart.options.plugins.title = { text: "Forecast Data", fontSize: 15 }
      this.chart.options.plugins.subtitles = { text: "Select Device ID from Dropdown Menu.", fontSize: 12 }
      this.chart.clear();
      return
    } else{
      console.log("DeviceId is not empty.. Updating chart.");
      this.chart.options.plugins.title = { display: true, text: "Forecast Data", fontSize: 15 }
      this.chart.options.plugins.subtitle = { display: true, text: "ID: "+deviceId, fontSize: 12 }
      this.chart.update();
    }

    var plot_past_training = false;
    var plot_current_training = false;
    var plot_prediction = true;

    const checkbox_past_training = document.getElementById(
        'chkPast',
    ) as HTMLInputElement | null;

    plot_past_training = checkbox_past_training.checked === true;

    const checkbox_current_training = document.getElementById(
        'chkCurrent',
    ) as HTMLInputElement | null;

    plot_current_training = checkbox_current_training.checked === true;

    const checkbox_current_forecast = document.getElementById(
        'chkForecast',
    ) as HTMLInputElement | null;

    plot_prediction = checkbox_current_forecast.checked === true;

    // Past Training Data (DataPoint1)
    if (plot_past_training){
      this.deviceService.getDeviceForecastData$(deviceId, 'past_training').subscribe((response: any) => {
        let data = response;

        if (data?.length){
          for(let i = 0; i < data.length; i++){
            // Demand
            this.dataPoints1.push({x: new Date(data[i].timestamp), y: Number(data[i].demand) });
            // feelsLike
            if (data[i].feelsLike != null){
              this.dataPoints1_temp.push({x: new Date(data[i].timestamp), y: Number(data[i].feelsLike) });
            }
            // normalIrradiance
            if (data[i].normalIrradiance != null){
              this.dataPoints1_dni.push({x: new Date(data[i].timestamp), y: Number(data[i].normalIrradiance) });
            }
            // cloudCover
            if (data[i].cloudCover != null){
              this.dataPoints1_cloudCover.push({x: new Date(data[i].timestamp), y: Number(data[i].cloudCover) });
            }
            // precipitationRate
            if (data[i].precipitationRate != null){
              this.dataPoints1_precipitationRate.push({x: new Date(data[i].timestamp), y: Number(data[i].precipitationRate) });
            };
          }

          if (this.dataPoints1.length > 0) {
            let dataset_demand = this.prepDataset(this.dataPoints1, 'Past Training Input (Demand)', 'blue', 'y');
            this.chart.data.datasets.push(dataset_demand);
          }
          if (this.dataPoints1_temp.length > 0) {
            let dataset_temp = this.prepDataset(this.dataPoints1_temp, 'Past Training Input (FeelsLike)', 'orange', 'y2');
            this.chart.data.datasets.push(dataset_temp);
          }
          if (this.dataPoints1_dni.length > 0) {
            let dataset_dni = this.prepDataset(this.dataPoints1_dni, 'Past Training Input (Normal Irradiance)', 'crimson', 'y3');
            this.chart.data.datasets.push(dataset_dni);
          }
          if (this.dataPoints1_cloudCover.length > 0) {
            let dataset_cloudCover = this.prepDataset(this.dataPoints1_cloudCover, 'Past Training Input (Cloud Cover)', 'darkkhaki', 'y3');
            this.chart.data.datasets.push(dataset_cloudCover);
          }
          if (this.dataPoints1_precipitationRate.length > 0) {
            let dataset_precipitationRate = this.prepDataset(this.dataPoints1_precipitationRate, 'Past Training Input (Precipitation Rate)', 'cyan', 'y4');
            this.chart.data.datasets.push(dataset_precipitationRate);
          }
          this.chart.update();
        }})
    }

    // Current Training Data (DataPoint2)
    if (plot_current_training){

      this.deviceService.getDeviceForecastData$(deviceId, 'current_training').subscribe((response: any) => {
        let data = response;

        if (data?.length){
          for(let i = 0; i < data.length; i++){
            // Demand
            this.dataPoints2.push({x: new Date(data[i].timestamp), y: Number(data[i].demand) });
            // feelsLike
            if (data[i].feelsLike != null){
              this.dataPoints2_temp.push({x: new Date(data[i].timestamp), y: Number(data[i].feelsLike) });
            }
            // normalIrradiance
            if (data[i].normalIrradiance != null){
              this.dataPoints2_dni.push({x: new Date(data[i].timestamp), y: Number(data[i].normalIrradiance) });
            }
            // cloudCover
            if (data[i].cloudCover != null){
              this.dataPoints2_cloudCover.push({x: new Date(data[i].timestamp), y: Number(data[i].cloudCover) });
            }
            // precipitationRate
            if (data[i].precipitationRate != null){
              this.dataPoints2_precipitationRate.push({x: new Date(data[i].timestamp), y: Number(data[i].precipitationRate) });
            };
          }

          if (this.dataPoints2.length > 0) {
            let dataset_demand = this.prepDataset(this.dataPoints2, 'Current Training Input (Demand)', 'blue', 'y');
            this.chart.data.datasets.push(dataset_demand);
          }
          if (this.dataPoints2_temp.length > 0) {
            let dataset_temp = this.prepDataset(this.dataPoints2_temp, 'Current Training Input (FeelsLike)', 'orange', 'y2');
            this.chart.data.datasets.push(dataset_temp);
          }
          if (this.dataPoints2_dni.length > 0) {
            let dataset_dni = this.prepDataset(this.dataPoints2_dni, 'Current Training Input (Normal Irradiance)', 'crimson', 'y3');
            this.chart.data.datasets.push(dataset_dni);
          }
          if (this.dataPoints2_cloudCover.length > 0) {
            let dataset_cloudCover = this.prepDataset(this.dataPoints2_cloudCover, 'Current Training Input (Cloud Cover)', 'darkkhaki', 'y3');
            this.chart.data.datasets.push(dataset_cloudCover);
          }
          if (this.dataPoints2_precipitationRate.length > 0) {
            let dataset_precipitationRate = this.prepDataset(this.dataPoints2_precipitationRate, 'Current Training Input (Precipitation Rate)', 'cyan', 'y4');
            this.chart.data.datasets.push(dataset_precipitationRate);
          }
          this.chart.update();

        } else {
          console.log('Demand data does not exist.');
        }

      })
    }

    // Current Forecast Output (calling /forecast) (DataPoint4)
    if (plot_prediction){
      this.deviceService.getForecast$(deviceId).subscribe((response: any) => {
        let data = response.forecast[0].timeseries;
        for(let i = 0; i < data.length; i++){
          this.dataPoints4.push({x: new Date(data[i].timestamp), y: Number(data[i].forecast) });
        }

        if (this.dataPoints4.length > 0) {
          let dataset_demand = this.prepDataset(this.dataPoints4, 'Current Forecast (Demand)', 'blue', 'y');
          // dataset_demand.borderDash = [10,5,10];
          this.chart.data.datasets.push(dataset_demand);
        }
        this.chart.update();
      })
    }

    // Current Forecast Input (through forecast_data method) (DataPoint3)
    if (plot_prediction){
      this.deviceService.getDeviceForecastData$(deviceId, 'prediction').subscribe((response: any) => {
        let data = response;

        if (data.length && data[0].demand) {
          for (let i = 0; i < data.length; i++) {
            // Demand
            this.dataPoints3.push({x: new Date(data[i].timestamp), y: Number(data[i].demand)});
            // feelsLike
            if (data[i].feelsLike != null) {
              this.dataPoints3_temp.push({x: new Date(data[i].timestamp), y: Number(data[i].feelsLike)});
            }
            // normalIrradiance
            if (data[i].normalIrradiance != null) {
              this.dataPoints3_dni.push({x: new Date(data[i].timestamp), y: Number(data[i].normalIrradiance)});
            }
            // cloudCover
            if (data[i].cloudCover != null) {
              this.dataPoints3_cloudCover.push({x: new Date(data[i].timestamp), y: Number(data[i].cloudCover)});
            }
            if (data[i].precipitationRate != null) {
              this.dataPoints3_precipitationRate.push({x: new Date(data[i].timestamp), y: Number(data[i].precipitationRate)});
            }
          }

          let dataset1 = this.prepDataset(this.dataPoints3, 'Current Forecast Input (Demand)', 'green', 'y');
          this.chart.data.datasets.push(dataset1);
          let dataset2 = this.prepDataset(this.dataPoints3_temp, 'Current Forecast Input (feelsLike)', 'orange', 'y2');
          this.chart.data.datasets.push(dataset2);
          let dataset3 = this.prepDataset(this.dataPoints3_dni, 'Current Training Input (Normal Irradiance)', 'crimson', 'y3');
          this.chart.data.datasets.push(dataset3);
          let dataset4 = this.prepDataset(this.dataPoints3_cloudCover, 'Current Training Input (Cloud Cover)', 'darkkhaki', 'y4');
          this.chart.data.datasets.push(dataset4);
          let dataset5 = this.prepDataset(this.dataPoints3_precipitationRate, 'Current Training Input (Precipitation Rate)', 'cyan', 'y5');
          this.chart.data.datasets.push(dataset5);

          this.chart.update();
        } else {
          console.log('Demand Data does not exist.');
        }
      })
    }

  };

  onChartResetClick() {
    this.chart.resetZoom();
  };


}
