import { ElementRef, Injectable } from '@angular/core';
import { InlineEditComponent } from '../../shared/component/global/inline-edit/inline-edit.component';

@Injectable()
export class InlineEditService<T> {
  readonly existingEdits = new Map<
    ElementRef<InlineEditComponent<T>>,
    () => void
  >();

  registerEdit(
    newEdit: ElementRef<InlineEditComponent<T>>,
    esc: () => void,
  ): void {
    this.existingEdits.set(newEdit, esc);
  }

  unregisterEdit(edit: ElementRef<InlineEditComponent<T>>): void {
    this.existingEdits.delete(edit);
  }

  unregisterAll(): void {
    const keys = Array.from(this.existingEdits.keys());
    keys.forEach(key => {
      this.existingEdits.delete(key);
    });
  }
}
