import { Configuration, Device, Location, Username } from '@model';

export interface Site {
  id: string;
  parentId: string;
  displayLabel: string;
  displayLabels: Record<string, string>;
  description: string;
  descriptions: Record<string, string>;
  deviceId: string;
  sid: string,
  status: string;
  timezone: string;
  locale: string;
  children: Device[];
  createdBy: Username;
  createdDate: string;
  lastUpdatedDate: string;
  settings: Location;
  forecastStatus: Configuration[];
  controlMode: string;
  derStatus: string;
  spaceType: string;
}

export class Site {
  constructor() {
    this.id = '';
    this.parentId = '';
    this.displayLabel = '';
    this.displayLabels = {};
    this.description = '';
    this.descriptions = {};
    this.deviceId = '';
    this.status = '';
    this.timezone = '';
    this.locale = '';
    this.children = new Array<Device>();
    this.createdBy = {} as any as Username;
    this.createdDate = '';
    this.lastUpdatedDate = '';
    this.settings = new Location();
    this.forecastStatus = new Array<Configuration>();
    this.controlMode = '';
    this.derStatus = '';
    this.spaceType = 'Site';
  }
}
