<ng-template #emptyTemplate>
  <div class="subsection-container">
    <span class="ml-3">{{ 'site.der.no_data' | translate}}</span>
  </div>
</ng-template>

<div>
  <div class="section-header-container">
    <h4 class="section-header" data-automation="summary-page-solar-header">
      {{ 'site.information' | translate }}
    </h4>
  </div>

  <div class="details-row first-row">
    <div class='details-half-row site-id-container'>
      <span class="text-light-color label-fixed-width"
            data-automation="site-id-label">{{ 'site.information.site_id' | translate }}:</span>
      <div id="site-id">
        <span data-automation="site-id">{{ site.id }}</span>
      </div>
    </div>
    <div class='details-half-row site-device-id-container'>
      <span class="text-light-color label-fixed-width"
            data-automation="site-name">{{ 'site.information.device_name' | translate }}:</span>
      <div id="site-name">
        <span data-automation="site-name">{{ site.displayLabel }}</span>
      </div>
    </div>
  </div>

  <div class="details-row">
    <div class='details-half-row site-device-name-container'>
      <span class="text-light-color label-fixed-width"
            data-automation="site-device-name-label">{{ 'site.information.device_name' | translate }}:</span>
      <div id="site-device-name">
        <span data-automation="site-device-name">{{ site.displayLabel }}</span>
      </div>
    </div>
    <div class='details-half-row site-device-id-container'>
      <span class="text-light-color label-fixed-width"
            data-automation="site-device-id">{{ 'site.information.device_id' | translate }}:</span>
      <div id="site-device-id">
        <span data-automation="site-device-id">{{ site.deviceId }}</span>
      </div>
    </div>
  </div>

  <div class="details-row">
    <div class='details-half-row site-timezone-container'>
      <span class="text-light-color label-fixed-width"
            data-automation="site-timezone-label">{{ 'site.timezone' | translate }}:</span>
      <div id="site-timezone">
        <span data-automation="site-timezone">{{ site.timezone }}</span>
      </div>
    </div>
    <div class='details-half-row site-address-container'>
      <span class="text-light-color label-fixed-width"
            data-automation="site-address-label">{{ 'site.information.address' | translate }}:</span>
      <div id="site-address">
        <span data-automation="site-address">{{ site.settings.address }}</span>
      </div>
    </div>
  </div>

  <div class="details-row">
    <div class='details-half-row site-latitude-container'>
      <span class="text-light-color label-fixed-width"
            data-automation="site-latitude-label">{{ 'site.information.latitude' | translate }}:</span>
      <div id="site-latitude">
        <span data-automation="site-latitude">{{ site.settings.latitude }}</span>
      </div>
    </div>
    <div class='details-half-row site-longitude-container'>
      <span class="text-light-color label-fixed-width"
            data-automation="site-longitude-label">{{ 'site.information.longitude' | translate }}:</span>
      <div id="site-longitude">
        <span data-automation="site-longitude">{{ site.settings.longitude }}</span>
      </div>
    </div>
  </div>

  <div class="details-row">
    <div class='details-half-row site-delivery-before-container'>
      <span class="text-light-color label-fixed-width"
            data-automation="site-delivery-before-label">{{ 'site.information.delivery_before' | translate }}:</span>
      <div id="site-delivery-before">
        <span data-automation="site-delivery-before">{{ site.settings.deliveryBefore }}</span>
      </div>
    </div>
    <div class='details-half-row site-delivery-after-container'>
      <span class="text-light-color label-fixed-width"
            data-automation="site-delivery-after-label">{{ 'site.information.delivery_after' | translate }}:</span>
      <div id="site-delivery-after">
        <span data-automation="site-delivery-after">{{ site.settings.deliveryAfter }}</span>
      </div>
    </div>
  </div>

  <div class="details-row">
    <div class='details-half-row site-utility-container'>
      <span class="text-light-color label-fixed-width"
            data-automation="site-utility-label">{{ 'site.information.utility' | translate }}:</span>
      <div id="site-utility">
        <span data-automation="site-utility">{{ site.settings.utility }}</span>
      </div>
    </div>
    <div class='details-half-row site-commissioned-container'>
      <span class="text-light-color label-fixed-width"
            data-automation="site-commissioned-label">{{ 'site.information.commissioned' | translate }}:</span>
      <div id="site-commissioned">
        <span data-automation="site-commissioned">{{ site.settings.commissioned }}</span>
      </div>
    </div>
  </div>
</div>

<!--<div>Site:</div>-->
<!--<div>{{ site | json }}</div>-->
<!--<div></div>-->
