import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { ChartViewComponent  } from 'src/app/shared/component/chart-view/chart-view.component';
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [ChartViewComponent],
  exports: [ChartViewComponent],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        TranslateModule,
        MatRippleModule,
        MatIconModule,
    ],
})
export class ChartViewModule {}
