import {Component, Input, OnDestroy} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'premo-component',
  templateUrl: './premo.html',
  styleUrls: ['./premo.scss'],
})
export class PremoComponent implements OnDestroy {
  apiKey: string;
  subscriptions: Subscription[] = [];
  constructor(
      private translateService: TranslateService,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscriber) => subscriber.unsubscribe());
  }
}
