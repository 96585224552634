const ENVIRONMENT = {
  DEV: 'https://cdn.dev-apps.enelx.net',
  PREPRD: 'https://cdn.preprd-apps.enelx.com',
  PROD: 'https://cdn.apps.enelx.com',
};

export function getEnvironmentUrl() {
  let cdnURL = ENVIRONMENT.DEV;
  if (window.location.href.search('.preprd-apps.enelx.com') !== -1) {
    cdnURL = ENVIRONMENT.PREPRD;
  } else if (window.location.href.search('.apps.enelx.com') !== -1) {
    cdnURL = ENVIRONMENT.PROD;
  }
  return cdnURL;
}
