import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Device} from '@model';
import {DeviceService, GlobalAlertService, SiteService} from '@service';

@Injectable()
export class DeviceResolver  {
    constructor(
        private deviceService: DeviceService,
        private siteService: SiteService,
        private globalAlertService: GlobalAlertService,
    ) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<Device> {
        const deviceId =
            route.queryParamMap.get('deviceId') || route.paramMap.get('deviceId');

        return this.deviceService
            .getDevice$(deviceId)
            .pipe(
                catchError((err: unknown) => {
                    this.globalAlertService.setError(err.toString());
                    return throwError(() => new Error(err.toString()));
                }),
            );
    }
}
