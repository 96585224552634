import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { FittedLoaderComponent } from './fitted-loader.component';

@NgModule({
  declarations: [FittedLoaderComponent],
  exports: [FittedLoaderComponent],
  imports: [CommonModule, MatProgressSpinnerModule, TranslateModule],
})
export class FittedLoaderModule {}
