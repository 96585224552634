import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Device, Site } from '@model';
import { DeviceService, SiteService } from '@service';

@Component({
  selector: 'premo-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss'],
})
export class DeviceComponent implements OnInit, OnDestroy {
  subscriptions = [];
  device = new Device();
  sites: Site[] = [];

  constructor(
    private deviceService: DeviceService,
    private siteService: SiteService,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const paramSubscription = this.route.data.subscribe((data: Device) => {
      console.log('data: ', data);
      this.device = data['data'];
    });

    this.subscriptions.push(paramSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
