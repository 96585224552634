import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  private _deviceId = new BehaviorSubject<string | null>(null);
  deviceId$ = this._deviceId.asObservable();
 
  constructor() { }

  updateChart(deviceId: string){
    this._deviceId.next(deviceId); 
  }
}