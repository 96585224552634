import { DatePipe } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MapsAPILoader } from '@ng-maps/core';
import { Observable } from 'rxjs';
import { CustomErrorStateMatcher, Location } from '@model';
import { InlineEditService } from '@service';

export const CALENDAR_MODE_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'premo-inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss'],
  providers: [
    DatePipe,
    { provide: MAT_DATE_FORMATS, useValue: CALENDAR_MODE_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter },
  ],
})
export class InlineEditComponent<T> implements OnChanges, AfterContentChecked {
  @Input() htmlTag: 'h1' | 'span' | 'div';
  @Input() inputType:
      | 'input'
      | 'checkbox'
      | 'textarea'
      | 'select'
      | 'map'
      | 'date';
  @Input() formControl: FormControl;

  @Input() label: string;
  @Input() fieldToUpdate: string;

  @Input() selectArray?: T[];
  @Input() selectDisplayField?: keyof T;
  @Input() selectValueField?: keyof T;
  @Input() selectIdField?: keyof T;
  @Input() selectIdPrefix?: string;
  @Input() selectEnableFilter?: boolean;

  @Input() latitudeField?: string;
  @Input() longitudeField?: string;
  @Input() nodeToAttachMap?: HTMLElement;

  @Input() dateStringFormat?: string;

  isLoading = false;

  formFieldWidth = 300;
  inputExample = '';

  filteredSelectArray: T[];

  matcher = new CustomErrorStateMatcher();

  latitude: number;
  longitude: number;
  showMap = false;

  private geoCoder: google.maps.Geocoder;

  @ViewChild('mapSearch') mapSearch: ElementRef;
  @ViewChild('select') matSelect: MatSelect;
  @ViewChild('picker') matDatePicker: MatDatepicker<Date>;
  @ViewChild('searchSelectArray') searchSelectArray: ElementRef;
  @ViewChildren('edits') edits: QueryList<InlineEditComponent<unknown>>;

  @ViewChild('formFieldInput') formFieldInputER: ElementRef;
  @ViewChild('invisibleText') invTextER: ElementRef;
  @ViewChild('inputText') inputTextER: ElementRef;

  @Input() callback: (
      formControl: FormControl,
      fieldToUpdate: string,
  ) => Observable<unknown>;

  @Output() formControlChange = new EventEmitter<FormControl>();

  isEditing = false;
  innerHtml: string;

  constructor(
      private cd: ChangeDetectorRef,
      private inlineEditService: InlineEditService<T>,
      private mapsAPILoader: MapsAPILoader,
      private ngZone: NgZone,
      private datePipe: DatePipe,
      private readonly self: ElementRef<InlineEditComponent<T>>,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    this.showMap = false;
    if (this.selectArray) {
      this.filteredSelectArray = this.selectArray;
    }
    if (this.inputType === 'map' && changes['formControl']) {
      this.latitude =
          +changes['formControl']?.currentValue.value[this.latitudeField];
      this.longitude =
          +changes['formControl']?.currentValue.value[this.longitudeField];
      if (!this.latitude) {
        this.formControl.setValue(undefined);
      }
    }
    this.refreshInnerHtml(this.label);
  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  editMode(): void {
    if (this.inlineEditService.existingEdits.size > 0) {
      Array.from(this.inlineEditService.existingEdits.values())[0]();
    }
    this.showMap = false;
    this.isEditing = true;
    this.inlineEditService.registerEdit(this.self, this.escWithoutSaving);
    setTimeout(() => this.resizeInput(this.formControl.value));
    if (this.inputType === 'map') {
      setTimeout(() => this.initAutocomplete());
    }
    if (this.inputType === 'select') {
      setTimeout(() => this.matSelect.open());
    }
    if (this.inputType === 'date') {
      setTimeout(() => this.matDatePicker.open());
    }
  }

  editingEnd(label: string): void {
    if (!this.formControl.valid) return;
    if (!this.formControl.dirty) {
      this.escWithoutSaving();
      return;
    }
    this.callback(this.formControl, this.fieldToUpdate).subscribe(() => {
      this.formControlChange.emit(this.formControl);
      this.refreshInnerHtml(label);
      this.isEditing = false;
      this.inlineEditService.unregisterEdit(this.self);
      if (this.inputType === 'select' || this.inputType === 'date') {
        this.formControl.markAsPristine();
      }
    });
  }

  openedChange(open: boolean): void {
    if (!open && this.formControl.pristine) {
      this.escWithoutSaving();
    }
  }

  escWithoutSaving = (): void => {
    if (this.inputType === 'checkbox' && !this.latitude) {
      return;
    }
    if (this.inputType === 'map' && !this.latitude) {
      return;
    }
    this.isEditing = false;
    if (this.selectArray) {
      this.filteredSelectArray = this.selectArray;
    }
    this.inlineEditService.unregisterEdit(this.self);
  };

  refreshInnerHtml(label: string): void {
    const customLabel = label ?? '-';
    this.innerHtml = `<${this.htmlTag}>${customLabel}</${this.htmlTag}>`;
  }

  selectDisplayValue(value: string): string {
    const t = this.selectArray.find(
        c => c[this.selectValueField.valueOf()] === value,
    );
    return t[this.selectDisplayField.valueOf()];
  }

  moveMap(): void {
    const move = (): void => {
      if (this.showMap) {
        const mapNode = document.getElementById('map-detail');
        this.nodeToAttachMap.appendChild(mapNode);
      }
    };
    setTimeout(move);
  }

  filterSelectArray(): void {
    const searchKey = this.searchSelectArray.nativeElement.value;
    this.filteredSelectArray = this.selectArray.filter(arr =>
        arr[this.selectDisplayField.valueOf()]
            .toUpperCase()
            .includes(searchKey.toUpperCase()),
    );
  }

  defaultStartDate(): Date {
    return this.formControl.value;
  }

  onDateChange(selectedDate: unknown): void {
    this.matDatePicker.close();
    this.formControl.setValue(selectedDate);
    this.formControl.markAsDirty();
    this.editingEnd(
        this.datePipe.transform(selectedDate as Date, this.dateStringFormat),
    );
  }

  private initAutocomplete(): void {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      const options = {
        fields: ['formatted_address', 'geometry', 'name'],
        strictBounds: false,
        types: ['address'],
      };

      const autocomplete = new google.maps.places.Autocomplete(
          this.mapSearch.nativeElement as HTMLInputElement,
          options,
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          //get the place result
          const place = autocomplete.getPlace();

          //verify result
          if (!place.geometry) {
            return;
          }

          //set latitude, longitude and zoom
          const address = place.formatted_address;
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          const location = new Location(
              address,
              this.latitude,
              this.longitude,
          );
          this.formControl.setValue(location);
          this.editingEnd(location.address);
        });
      });
    });
  }

  resizeInput(input: string): void {
    this.inputExample = input;
    setTimeout(() => {
      this.formFieldWidth = this.invTextER.nativeElement.offsetWidth + 40;
    });
  }
}
