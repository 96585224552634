<div class="flex flex-col justify-start items-center">
  <div class="w-full flex justify-center items-center title-heading">
    <div class="flex justify-start items-center justify-self-start margin-right-auto icon-header">
      <div class="icon-container">
        <mat-icon svgIcon='manage-white' class='header-icon'></mat-icon>
      </div>
      <div>
        <h1 data-automation="geo-header" [translate]="'geoview.header'"></h1>
      </div>
    </div>
  </div>
</div>

<div class="container container-col">
  <premo-map-view></premo-map-view>
</div>

