import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResourceService } from './resource.service';
import { Device } from '@model';

@Injectable()
export class DeviceService {
  private readonly deviceEmitter = new Subject<Device[]>();

  apiUrl: string;

  constructor(private resourceService: ResourceService<Device>) {
    this.apiUrl = resourceService.apiLocation;
  }

  getDevices$(developerId: number | string): Observable<Device[]> {
    return this.resourceService.getList(`developers/${developerId}/devices`);
  }

  getAllDevices$(): Observable<Device[]> {
    return this.resourceService.getList(`types/device`).pipe(
      tap(devices => {
        devices.forEach(device => device.spaceType = 'Device');
        this.deviceEmitter.next(devices);
      }),
    );
  }

  getDevice$(deviceId: number | string): Observable<Device> {
    return this.resourceService.getById('devices', deviceId);
  }

  getDeviceForecastData$(deviceId: string, dataType: 'prediction' | 'past_training' | 'current_training'): Observable<any> {
    return this.resourceService.get(`devices/${deviceId}/forecast_data?data_type=${dataType}`);
  }

  getForecast$(deviceId: string): Observable<any> {
    return this.resourceService.get(`forecast?device_id=${deviceId}`);
  }

  getDeviceForecastInfo$(id: string): Observable<any> {
    return this.resourceService.get(`/devices/${id}/forecast_info`);
  }

  getDeviceForecastConfig$(id: string): Observable<any> {
    return this.resourceService.get(`/devices/${id}/get_forecast_config`);
  }

}
