import {Component, Input, OnChanges, ViewChild} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { detectBrowser } from '@helper';
import { Site } from '@model';
import { TimezonesService } from '@service';
import { InlineEditComponent } from "@component";

@Component({
  selector: 'premo-site-home',
  templateUrl: './site-home.html',
  styleUrls: ['./site-home.component.scss'],
})
export class SiteHomeComponent implements OnChanges {
  @Input() site: Site;
  @ViewChild('mapComponent') mapComponent: InlineEditComponent<Location>;
  lastRefreshed: string;
  isFirefox: boolean;

  constructor(
    private timezoneService: TimezonesService,
    private translateService: TranslateService,
  ) {
    this.isFirefox = detectBrowser().includes('Firefox');
  }

  ngOnChanges(): void {
    this.getLastSave();
  }

  getLastSave(): void {
    this.lastRefreshed = this.timezoneService.getDateTimeDisplayLabel(
      this.site.parentId,
      this.site.lastUpdatedDate,
    );
    if (this.lastRefreshed === '') {
      this.lastRefreshed = this.translateService.instant(
        'last.refreshed.never',
      );
    }
  }
}
