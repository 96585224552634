import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceComponent } from 'src/app/view/device/device.component';
import { DialogModule } from '@component';
import { NgMapsGoogleModule } from '@ng-maps/google';
import { NgMapsCoreModule } from '@ng-maps/core';

@NgModule({
  declarations: [DeviceComponent],
  exports: [DeviceComponent],
  imports: [
    CommonModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatRippleModule,
    MatIconModule,
    NgMapsGoogleModule,
    NgMapsCoreModule,
  ],
})
export class DeviceModule {}
