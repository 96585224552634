import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResourceService } from './resource.service';
import { Site } from '@model';


@Injectable()
export class SiteService {
  private readonly siteEmitter = new Subject<Site[]>();

  apiUrl: string;
  constructor(private resourceService: ResourceService<Site>) {
    this.apiUrl = resourceService.apiLocation;
    console.log("resourceService.apiLocation: " + this.apiUrl);
  }
  getAllSites$(): Observable<Site[]> {
    return this.resourceService.getList(`sites`).pipe(
      tap((sites) => {
        sites.forEach(site => site.spaceType = 'Site');
        this.siteEmitter.next(sites);
      }),
    );
  }

  getSite$(siteId: number | string): Observable<Site> {
    return this.resourceService.getById('sites', siteId);
  }

  getSitesCurrentForecastStatus$(): Observable<Site> {
    return this.resourceService.get('sites/current_forecast_status?format=json');
  }

  getForecastInfo$(deviceId: number | string): Observable<Site> {
    return this.resourceService.get('sites/'+deviceId+'/forecast_info');
  }

}
