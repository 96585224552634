import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { ChartComponent  } from 'src/app/shared/component/chart/chart.component';
import {CanvasJSAngularChartsModule} from "@canvasjs/angular-charts";

@NgModule({
  declarations: [ChartComponent],
  exports: [ChartComponent],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        TranslateModule,
        MatRippleModule,
        CanvasJSAngularChartsModule,
    ],
})
export class ChartModule {}
