import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { ChartService } from 'src/app/core/service/chart.service';
import { filter, map } from 'rxjs';
import { DeviceService } from '@service'

@Component({
  selector: 'premo-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit {
  dataPoints1:any = [];
  dataPoints2:any = [];
  dataPoints3:any = [];
  dataPoints4:any = [];
  chart:any;

  constructor(
    private chartService: ChartService,
    private http : HttpClient,
    private elementRef: ElementRef,
    private deviceService: DeviceService,
  ) {}


  ngOnInit(): void {
    this.chartService.deviceId$.pipe(
      filter(d => d !== null),
      map(d => d as string)
    ).subscribe(deviceId => {
      this.updateChart(deviceId);
    })
  }

  chartOptions = {
    theme: "dark2", //"light2",
    zoomEnabled: true,
    exportEnabled: true,
    animationEnabled: true,
    title: {
      text:"Forecast Data", 
      fontSize: 15
    },
    subtitles: [{
      text: "Enter site/device id in the search box.",
      fontSize: 12,
      horizontalAlign: "center",
      verticalAlign: "center",
      dockInsidePlotArea: true
    }],
    axisY: {
      title: "Load (in MW)",
      fontSize: 15,
      prefix: ""
    }, 
    data: [{
      type: "line",
      showInLegend: true, 
      name: "Past Training input",
      yValueFormatString: "#,###.00",
      xValueFormatString: "DD MMM YYYY HH:mm",
      xValueType: "dateTime",
      dataPoints: this.dataPoints1
    },
    {
      type: "line",
      showInLegend: true, 
      name: "Current Training input",
      yValueFormatString: "#,###.00",
      xValueFormatString: "DD MMM YYYY HH:mm",
      xValueType: "dateTime",
      dataPoints: this.dataPoints2
    },
    {
      type: "line",
      showInLegend: true, 
      name: "Prediction input",
      yValueFormatString: "#,###.00",
      xValueFormatString: "DD MMM YYYY HH:mm",
      xValueType: "dateTime",
      dataPoints: this.dataPoints3
    },
    {
      type: "line",
      showInLegend: true, 
      name: "Forecast",
      yValueFormatString: "#,###.00",
      xValueFormatString: "DD MMM YYYY HH:mm",
      xValueType: "dateTime",
      dataPoints: this.dataPoints4
    }]
  }
 
  getChartInstance(chart: object) {
    this.chart = chart;    
  }

  adjustSize(chart: object) {
    this.chart = chart;
    this.chart.newSize;
    this.renderChart();
  }

  renderChart() {
    // Render or update the chart based on the current container size
    const container = this.elementRef.nativeElement.querySelector('.chart-container');
    const width = container.offsetWidth;
    const height = container.offsetHeight;

    this.chart.setSize(width, height);
  }
  
  updateChart(deviceId: string): void{
    console.log(deviceId);
    this.dataPoints1 = [];
    this.dataPoints2 = [];
    this.dataPoints3 = [];
    this.dataPoints4 = [];
    this.chart.options.data[0].dataPoints = this.dataPoints1; 
    this.chart.options.data[1].dataPoints = this.dataPoints2; 
    this.chart.options.data[2].dataPoints = this.dataPoints3; 
    this.chart.options.data[3].dataPoints = this.dataPoints4;

    if (deviceId == '') {
      console.log("DeviceId is empty.. Clearing chart.")
      this.chart.options.title = { text: "Forecast Data", fontSize: 15 }
      this.chart.options.subtitles = { text: "Enter site/device id in the search box.", fontSize: 12 }
      this.chart.render();
      return
    }

    this.deviceService.getDeviceForecastData$(deviceId, 'past_training').subscribe((forecasts: any[]) => {
      for (let forecast in forecasts) {
        // @ts-ignore
        this.dataPoints1.push({x: new Date(forecast.timestamp), y: Number(forecast.demand) });
      }
      this.chart.render();
    });

    this.deviceService.getDeviceForecastData$(deviceId, 'current_training').subscribe((forecasts: any[]) => {
      for (let forecast in forecasts) {
        // @ts-ignore
        this.dataPoints2.push({x: new Date(forecast.timestamp), y: Number(forecast.demand) });
      }
      this.chart.render();
    });

    this.deviceService.getDeviceForecastData$(deviceId, 'prediction').subscribe((forecasts: any[]) => {
      for (let forecast in forecasts) {
        // @ts-ignore
        this.dataPoints3.push({x: new Date(forecast.timestamp), y: Number(forecast.demand) });
      }
      this.chart.render();
    });

    this.deviceService.getForecast$(deviceId).subscribe((forecasts: any[]) => {
      for (let forecast in forecasts) {
        // @ts-ignore
        for (let timeseries in forecast.timeseries) {
          // @ts-ignore
          this.dataPoints4.push({x: new Date(timeseries.timestamp), y: Number(timeseries.forecast)});
        }
      }
      this.chart.render();
    });

    this.chart.options = {
      ...this.chart.options,
      title: {text: "Forecast Data: " + deviceId, fontSize: 15},
      subtitles: {text: "", fontSize: 12},
    }
    this.chart.render();
  };
}
