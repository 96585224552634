import {Component, OnDestroy, OnInit} from '@angular/core';
import {detectBrowser} from '@helper';
import {Site, Device} from '@model';
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'premo-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnDestroy, OnInit {
  updates: string[];
  subscriptions: Subscription[] = [];
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.updates = this.route.snapshot.data['updates'];
  }

  goTo(url: string): void {
    window.open(url, '_blank');
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscriber) => subscriber.unsubscribe());
  }
}
