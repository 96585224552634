import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SiteHomeComponent } from 'src/app/view/site-home/site-home.component';
import {InlineEditModule} from "@component";

@NgModule({
  declarations: [SiteHomeComponent],
  exports: [SiteHomeComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MatIconModule,
        MatSortModule,
        MatRippleModule,
        ClipboardModule,
        MatSnackBarModule,
        MatTooltipModule,
        InlineEditModule,
    ],
})
export class SiteHomeModule {}
