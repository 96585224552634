    <div class="full p-12 flex">
    <div class="full flex flex-col justify-end items-start left">
        <div class="premo-welcome flex flex-col">
            <span class="welcome-text text-light-color" data-automation="welcome-title-1" [translate]="'welcome.part1'"></span>
            <span class="welcome-text text-primary-color premo-dashboard" data-automation="welcome-title-2">{{ "welcome.part2" | translate }}</span>

        <div data-automation="info-container"
             class="background-primary flex justify-between mt-1 p-8 pt-1 pb-12 text-container">
            <div class="mr-10">
                <p data-automation="updates-title" class="sub-header">{{ "welcome.latest_updates" | translate }}</p>
                <div data-automation="updates" class="updates">
                    <span *ngFor="let update of updates">{{ update }}</span>
                </div>
            </div>
            <div data-automation="quick-links">
                <p class="sub-header">{{ "welcome.quick_links" | translate }}</p>
                <p class="link"
                   (click)="goTo('https://confluence.springlab.enel.com/display/DERPM/Premo+Dashboard')">{{ "welcome.confluence_page" | translate }}</p>
            </div>
        </div>
    </div>
    <div data-automation="dashboard-image" class="cropped pr-20">
    </div>
</div>
