import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { GeoComponent } from 'src/app/view/geo/geo.component';
import {MapViewModule, SpinnerModule} from "@component";

@NgModule({
  declarations: [GeoComponent],
  exports: [GeoComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatRippleModule,
    TranslateModule,
    MatTooltipModule,
    SpinnerModule,
    MapViewModule,
  ],
})
export class GeoModule {}
