export interface Node {
  id: string;
  displayLabel: string;
  children?: Node[];
  status?: string;
}

export class Node {
  constructor(
    id: string,
    displayLabel: string,
    children: Node[],
    status: string,
  ) {
    this.id = id;
    this.displayLabel = displayLabel;
    this.children = children;
    this.status = status;
  }
}
