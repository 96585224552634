<div class="flex flex-col justify-start items-center">
  <div class="w-full flex justify-center items-center title-heading">
    <div class="flex justify-start items-center justify-self-start margin-right-auto icon-header">
      <div class="icon-container">
        <mat-icon svgIcon='manage-white' class='header-icon'></mat-icon>
      </div>
      <div>
        <h1 data-automation="site-header">{{site.displayLabel}}</h1>
      </div>

      <div class="site-tabs">
        <div class="flex items-center">
          <h3 [ngClass]="{'active-heading': mode === tabsEnum.details}" id="site-home-tab" (click)="changeTab(tabsEnum.details)" class="tab">{{ 'tabs.details' | translate}}</h3>
          <h3 [ngClass]="{'active-heading': mode === tabsEnum.configure}" id="site-configure-tab" (click)="changeTab(tabsEnum.configure)" class="tab">{{ 'tabs.configure' | translate}}</h3>
        </div>
      </div>
    </div>
  </div>
</div>

<div [ngSwitch]="mode" class="page-container">
  <premo-site-home *ngSwitchCase="tabsEnum.details" [site]="site"></premo-site-home>
  <premo-site-configure *ngSwitchCase="tabsEnum.configure" [site]="site"></premo-site-configure>
</div>
