import { DOCUMENT } from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CookieService, LoginRedirectService } from '@service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private loginRedirect: LoginRedirectService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (request.headers.has('Authorization')) {
      return next.handle(request);
    } else {
      if (!this.cookieService.getEnocSession()) {
        if (!this.loginRedirect.urlToNavigate) {
          this.loginRedirect.setLogout();
        } else {
          this.document.location.href = this.loginRedirect.urlToNavigate;
        }
      }
      const requestAuth = request.clone({
        setHeaders: {
          Authorization: this.cookieService.getEnocSession(),
        },
      });
      return next.handle(requestAuth);
    }
  }
}
