import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Device, Site } from '@model';
import {DeviceService, GoogleService, TreeService} from '@service';

enum Tabs {
  details = 'details',
  configure = 'configure'
}

@Component({
  selector: 'premo-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss'],
})
export class SiteComponent implements OnInit, AfterViewInit, OnDestroy {

  tabsEnum: typeof Tabs = Tabs;
  mode: Tabs = Tabs.details;
  site: Site;
  device: Device;
  apiKey: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private treeService: TreeService,
    private googleService: GoogleService,
    private deviceService: DeviceService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.data.subscribe(data => {
        const site: Site = data['data'];
        this.treeService.selectSiteInList(site);
        this.site = site;
        if (!site.deviceId) {
          return ;
        }
      }),
    );

    this.apiKey = this.googleService.apiKey;
    const queryTab = this.route.snapshot.queryParamMap.get('tab');
    this.mode = queryTab === Tabs.details.toString() ? Tabs[queryTab] : Tabs.details;
    setTimeout(() => this.appendModeToQueryParam(this.mode), 1000);
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.appendModeToQueryParam(this.mode), 1000);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  changeTab(mode: Tabs): void {
    this.mode = mode;
    this.appendModeToQueryParam(mode);
  }

  private appendModeToQueryParam(
    mode: Tabs,
  ): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: mode,
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      // skipLocationChange: true,
      // do not trigger navigation
    });
  }
}
