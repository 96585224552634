import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// Translate
import { CookieService } from './cookie.service';

// this language will be used as a fallback when a translation isn't found in the current language
export const DEFAULT_LOCALE = 'en-us';
const DEFAULT_BRAND = 'CALIBRANT';
const SUPPORTED_LOCALES = ['en-us'];
const SUPPORTED_BRANDS = ['calibrant'];

/**
 * Wrapping the translateService, including fallback rules, branding, etc
 */
@Injectable()
export class I18nService {
  locale: string;
  brand: string;
  currencyFormatter: Intl.NumberFormat;
  shortDateFormatter: Intl.DateTimeFormat;
  shortDateMonthFormatter: Intl.DateTimeFormat;
  longDateFormatter: Intl.DateTimeFormat;
  brandStyling: Record<string, unknown>;

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.translate.setDefaultLang(`${DEFAULT_LOCALE}.${DEFAULT_BRAND}`);

    this.brandStyling = SUPPORTED_BRANDS.reduce((acc, v) => {
      acc[v] = `${v}.css`;
      return acc;
    }, {});
  }

  setLocale(locale: string): void {
    if (typeof locale === 'string') {
      locale = locale.toLowerCase();
      if (locale.indexOf('_') !== -1) {
        locale = locale.replace('_', '-');
      }
      if (!SUPPORTED_LOCALES.includes(locale)) {
        locale = DEFAULT_LOCALE;
      }
    }
    this.locale = locale || DEFAULT_LOCALE;
    if (!this.isNotEmpty(this.locale)) {
      this.locale = DEFAULT_LOCALE;
    }
  }

  setBrand(brand: string): void {
    if (!SUPPORTED_BRANDS.includes(brand.toLowerCase())) {
      brand = DEFAULT_BRAND;
    }
    this.brand = brand || DEFAULT_BRAND;
    if (!this.isNotEmpty(this.brand)) {
      this.brand = DEFAULT_BRAND;
    }
    // this.setStyle();
  }

  isNotEmpty(value: string): boolean {
    return (
      typeof value === 'string' && value.length > 0 && value !== 'undefined'
    );
  }

  setCurrencyFormatter(currency: string): void {
    this.currencyFormatter = this.getCurrencyFormatterForCurrency(currency);
  }

  setDateFormatters(locale: string): void {
    this.shortDateMonthFormatter = new Intl.DateTimeFormat(locale, {
      month: 'short',
      day: '2-digit',
    });

    this.shortDateFormatter = new Intl.DateTimeFormat(locale, {
      // year : 'numeric',
      year: 'numeric',
      month: 'short',
    });

    this.longDateFormatter = new Intl.DateTimeFormat(locale, {
      weekday: 'long',
      day: '2-digit',
      year: 'numeric',
      month: 'short',
    });
  }

  getCurrencyFormatterForCurrency(currency: string): Intl.NumberFormat {
    const numberFormatterOpts = {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
    };

    return new Intl.NumberFormat(this.locale, numberFormatterOpts);
  }

  loadLocale(): void {
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(this.locale).subscribe(() => {
      document.title = this.translate.instant('APP.TITLE');
    });
    this.setDateFormatters(this.locale);
    this.setCurrencyFormatter('USD');
  }
}
