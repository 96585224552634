<link rel="stylesheet" href="https://js.arcgis.com/4.30/esri/themes/light/main.css" />
<script type="module" src="https://js.arcgis.com/calcite-components/2.11.1/calcite.esm.js"></script>
<link rel="stylesheet" type="text/css" href="https://js.arcgis.com/calcite-components/2.11.1/calcite.css" />
<div class="app-wrapper">
  <div class="main-div">
    <label class="switch">
      <input id="checkboxId" type="checkbox" [checked]="featureTableShown" (click)=selectionChange() />
      <span class="slider round"></span>
    </label>
    <label class="label-text" id="labelText">{{featureTableShown?"Hide Feature Table":"Show Feature Table"}}</label>
  </div>
  <div id="map-contents" class="container">
    <as-split direction="vertical" unit="percent" class="container">
      <as-split-area id="splitTop" [size]=50 order="1">
        <div #mapViewNode class="map-view-div"></div>
      </as-split-area>
      <as-split-area id="splitBottom" [size]=50 order="2" class="container">
        <div class="bottom-control-wrapper">
          <label id="forecastStateLabel" for="forecastState" class="label">Forecast Status: </label>
          <select id="forecastState" name="forecastState" class="label">
            <option value="all" selected>Show All</option>
          </select>
          <label for="deviceType" id="deviceTypeLabel" class="label">Device Type: </label>
          <select id="deviceType" name="deviceType" class="label">
            <option value="all" selected>Show All</option>
          </select>
          <label for="siteId" id="deviceIdLabel" class="label">Forecasted Device ID: </label>
          <select id="siteId" name="deviceSelection" class="device-label" contenteditable="true"></select>
          <br>
          <button label="Data Grid"
                  (click)="setToGridMode()"
                  mat-icon-button
                  color="primary"
                  class="mat-button">
            <mat-icon aria-label="Show Grid" matTooltip="Show Grid">grid_on</mat-icon>
          </button>
          <button label="Show Plot"
                  (click)="setToPlotMode()"
                  mat-icon-button
                  color="primary"
                  class="mat-button">
            <mat-icon aria-label="Show Plot" matTooltip="Show Plot">insert_chart_outlined</mat-icon>
          </button>
          <button id="plotchart"
                  (click)="updateTSChart()"
                  mat-icon-button
                  color="primary"
                  class="mat-button">
            <mat-icon aria-label="Plot Data" matTooltip="Plot Data">auto_graph</mat-icon>
          </button>
          <button id="clearchart"
                  (click)="clearChart()"
                  mat-icon-button
                  color="primary"
                  class="mat-button">
            <mat-icon aria-label="Clear Plot" matTooltip="Clear Plot">delete_outline</mat-icon>
          </button>
        </div>
        <div id="tableWrapper" class="table-wrapper">
          <div #tableContainer></div>
        </div>
        <div id="chartWrapper" class="chart-wrapper">
          <premo-chart-view class="container"></premo-chart-view>
        </div>
      </as-split-area>
    </as-split>
  </div>
</div>

<ng-template #secondDialog>
  <h2 matDialogTitle>Missing ID</h2>
  <p matDialogContent>Enter Forecasted Device/Site ID in order to plot data.</p>
  <mat-dialog-actions align="end">
    <button MatButton matDialogClose>Dismiss</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #thirdDialog>
  <h2 matDialogTitle>Forecast Data Not Ready</h2>
  <p matDialogContent>Specified Device/Site ID has non Ready Status.</p>
  <mat-dialog-actions align="end">
    <button MatButton matDialogClose>Dismiss</button>
  </mat-dialog-actions>
</ng-template>