import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { HomeComponent } from 'src/app/view/home/home.component';
import {MatLegacyTableModule as MatTableModule} from "@angular/material/legacy-table";
import { RouterModule } from '@angular/router';
import {MatStepperModule} from "@angular/material/stepper";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
@NgModule({
  declarations: [HomeComponent],
  exports: [HomeComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MatIconModule,
        MatSortModule,
        MatRippleModule,
        ClipboardModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatTableModule,
        RouterModule,
        MatStepperModule,
        MatButtonModule
    ],
})
export class HomeModule {}
