<ng-template #emptyTemplate>
  <div class="subsection-container">
    <span class="ml-3">{{ 'site.der.no_data' | translate}}</span>
  </div>
</ng-template>

<div>
  <div class="section-header-container">
    <h4 class="section-header" data-automation="summary-page-solar-header">
      {{ 'site.configurations' | translate }}
    </h4>
  </div>

  <div class="details-row first-row">
    <div class='details-half-row site-device-name-container'>
      <span class="text-light-color label-fixed-width"
            data-automation="site-device-name-label">{{ 'site.information.device_name' | translate }}:</span>
      <div id="site-device-name">
        <span data-automation="site-device-name">{{ site.displayLabel }}</span>
      </div>
    </div>
    <div class='details-half-row site-device-id-container'>
      <span class="text-light-color label-fixed-width"
            data-automation="site-device-id">{{ 'site.information.device_id' | translate }}:</span>
      <div id="site-device-id">
        <span data-automation="site-device-id">{{ site.deviceId }}</span>
      </div>
    </div>
  </div>

  <div class="details-row">
    <div class='details-half-row site-timezone-container'>
      <span class="text-light-color label-fixed-width"
            data-automation="site-timezone-label">{{ 'site.timezone' | translate }}:</span>
      <div id="site-timezone">
        <span data-automation="site-timezone">{{ site.timezone }}</span>
      </div>
    </div>
    <div class='details-half-row site-address-container'>
      <span class="text-light-color label-fixed-width"
            data-automation="site-address-label">{{ 'site.information.address' | translate }}:</span>
      <div id="site-address">
        <span data-automation="site-address">{{ site.settings.address }}</span>
      </div>
    </div>
  </div>

</div>