import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SiteComponent } from './site.component';
import {SiteHomeModule} from "src/app/view/site-home/site-home.module";
import {SiteConfigureModule} from "src/app/view/site-configure/site-configure.module";

@NgModule({
  declarations: [SiteComponent],
  exports: [SiteComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatRippleModule,
        TranslateModule,
        MatTooltipModule,
        SiteHomeModule,
        SiteConfigureModule
    ],
})
export class SiteModule {}
