import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatRippleModule } from '@angular/material/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from '../loader/loader.module';
import { DialogComponent } from '@component';

@NgModule({
  declarations: [DialogComponent],
  exports: [DialogComponent],
  imports: [
    MatDialogModule,
    TranslateModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatRippleModule,
    CommonModule,
    LoaderModule,
  ],
})
export class DialogModule {}
