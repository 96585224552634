import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../guard';
import {PermissionEnum} from '@model';
import {DeviceComponent, HomeComponent, PremoModule, SiteComponent, GeoComponent} from '@view';
import {DeviceResolver, SiteResolver} from '@resolver';

enum Routing {
    doc = 'doc',
    list = 'list',
    root = '',
    viewDevice = 'details/:deviceId/view-device',
    viewSite = 'details/:siteId/view-site',
    viewGeo = 'geo',
}

const routerOptions: ExtraOptions = {
    onSameUrlNavigation: 'reload',
    enableTracing: false
};

const routes: Routes = [
    {
        path: Routing.viewDevice,
        component: DeviceComponent,
        canActivate: [AuthGuard],
        data: {requiredPermissions: [PermissionEnum.read]},
        resolve: {data: DeviceResolver},
    },
    {
        path: Routing.viewSite,
        component: SiteComponent,
        canActivate: [AuthGuard],
        data: {requiredPermissions: [PermissionEnum.update]},
        resolve: {data: SiteResolver},
    },
    {
        path: Routing.viewGeo,
        component: GeoComponent,
        canActivate: [AuthGuard],
        data: {requiredPermissions: [PermissionEnum.update]},
    },
    {
        path: Routing.root,
        component: HomeComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard],
        data: {requiredPermissions: [PermissionEnum.read]},
    },
    {
        path: Routing.doc,
        redirectTo: '/doc/index.html',
    },
    {
        path: '**',
        redirectTo: '/',
    },
];

@NgModule({
    imports: [
        PremoModule,
        RouterModule.forRoot(routes, routerOptions),
    ],
    exports: [RouterModule],
    providers: [AuthGuard, DeviceResolver, SiteResolver],
})
export class CoreRoutingModule {
}
