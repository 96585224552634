export interface Forecast {
  deviceForecasterKind: string;
  forecasterId: number;
  state: string;
  siteId: string;
  deviceId: string;
  device_type: string;
  deviceModelName: string;
  forecaster_kwargs: string;
  forecaster_max_consecutive_gaps_forecasting: number;
  forecaster_max_consecutive_gaps_training: number;
  forecaster_stochastic: boolean;
  forecaster_use_weather_features: boolean;
  forecaster_weather_features: string;
  timestampInitialized: string;
  last_forecasted_duration: string;
  last_forecasted_ts: string;
  last_trained_data: string;
  last_trained_ts: string;
  target_col: string;
  model_base_dir: string;
  model_mae: number;
  model_mape: number;
  model_name: string;
}

export class Forecast {
  constructor() {
    this.deviceForecasterKind = '';
    this.forecasterId = 0;
    this.state = '';
    this.siteId = '';
    this.deviceId = '';
    this.device_type = '';
    this.deviceModelName = '';
    this.forecaster_kwargs = '';
    this.forecaster_max_consecutive_gaps_forecasting = 0;
    this.forecaster_max_consecutive_gaps_training = 0;
    this.forecaster_stochastic = false;
    this.forecaster_use_weather_features = false;
    this.forecaster_weather_features = '';
    this.timestampInitialized = '';
    this.last_forecasted_duration = '';
    this.last_forecasted_ts = '';
    this.last_trained_data = '';
    this.last_trained_ts = '';
    this.target_col = '';
    this.model_base_dir = '';
    this.model_mae = 0;
    this.model_mape = 0;
    this.model_name = '';
  }
}
