<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<div class="app-wrapper">
    <div class="chart-control-wrapper">
        <div class="checkboxes">
            <label class="label-text label-bold">Select Input: </label><br>
            <label class="switch"><input id="chkPast" type="checkbox"> <span class="slider round"></span></label>
            <label class="label-text" id="labelText1">Past Training</label>
            <br>
            <label class="switch"><input id="chkCurrent" type="checkbox"> <span class="slider round"></span></label>
            <label class="label-text" id="labelText2">Current Training</label>
            <br>
            <label class="switch"><input id="chkForecast" type="checkbox" [checked]="true"> <span class="slider round"></span></label>
            <label class="label-text" id="labelText3">Forecast</label>
            <br>
            <button id="btnReset" mat-icon-button color="primary" class="mat-button" aria-label="Zoom out" (click)="onChartResetClick()">
                <mat-icon matTooltip="Zoom out">zoom_out_map</mat-icon>
                <span class="a">ZOOM OUT</span>
            </button>
        </div>
    </div>
    <div class="chart-container">
        <canvas id="premo-chart" ></canvas>
    </div>
</div>