<div class="flex flex-col justify-start items-center">
  <div class="w-full flex justify-center items-center title-heading">
    <div class="flex justify-start items-center justify-self-start margin-right-auto icon-header">
      <div class="icon-container">
        <mat-icon svgIcon='manage-white' class='header-icon'></mat-icon>
      </div>
      <div>
        <h1>{{device.displayLabel}}</h1>
      </div>
    </div>
  </div>
</div>

<div class="page-container">
  <div class="section-header-container">
    <h4 class="section-header" data-automation="site-locations-label" [translate]="'sites.locations'">
    </h4>
  </div>

  <div class="section-header-container">
    <h4 class="section-header" data-automation="current-status-label" [translate]="'device.overview'">
    </h4>
  </div>
</div>
