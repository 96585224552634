import { ElementRef, Injectable } from '@angular/core';
import { LoaderComponent } from '@component';

@Injectable()
export class LoaderService {
  #_excludedUiUrls: string[] = [];
  #_regexEndpoints: RegExp[] = [];
  #_ignoreNext: string;
  private _isLoading: boolean;
  private callInProgress = 0;
  private readonly existingLoaders: ElementRef<LoaderComponent>[] = [];

  get isLoading(): boolean {
    return this._isLoading;
  }

  set isLoading(loading: boolean) {
    this._isLoading = loading;
  }

  get excludedUiUrls(): string[] {
    return this.#_excludedUiUrls;
  }

  get ignoreNext(): string {
    return this.#_ignoreNext;
  }

  set regexEndpoints(patterns: string[]) {
    this.#_regexEndpoints = patterns.map(p => new RegExp(p));
  }

  getRegexEndpoints(): RegExp[] {
    return this.#_regexEndpoints;
  }

  setExcludedUiUrls(...excluded: string[]): void {
    this.#_excludedUiUrls.push(...excluded);
  }

  start(): void {
    this._isLoading = true;
    this.callInProgress++;
  }

  end(): void {
    if (this.callInProgress === 0) {
      return;
    }
    this.callInProgress--;
    if (this.callInProgress === 0) {
      this._isLoading = false;
    }
  }

  terminate(): void {
    if (this.callInProgress === 0) {
      return;
    }
    this.callInProgress--;
    this._isLoading = false;
  }

  /**
   * Registers a new loader to the existing loaders array
   * @param newLoader: the component to register
   */
  registerLoader(newLoader: ElementRef<LoaderComponent>): void {
    this.existingLoaders.forEach(loader => {
      loader.nativeElement['style']['display'] = 'none';
    });
    this.existingLoaders.push(newLoader);
  }

  /**
   * Called when a component that contains a loader is destroyed
   * @param loader: the loader to unregister
   */
  unregisterLoader(loader: ElementRef<LoaderComponent>): void {
    this.existingLoaders.splice(this.existingLoaders.indexOf(loader), 1);
    if (this.existingLoaders.length) {
      this.existingLoaders[this.existingLoaders.length - 1].nativeElement[
        'style'
      ]['display'] = 'inherit';
    }
  }

  setIgnoreNext(endpoint: string): void {
    this.#_ignoreNext = endpoint;
  }
}
