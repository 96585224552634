<!--<premo-global-alert></premo-global-alert>-->

<global-nav #nav [navUrl]="navUrl"></global-nav>

<mat-sidenav-container (backdropClick)="sidenavOpen = false">
    <mat-sidenav
        #sidenav
        class="sidenav-container"
        fixedInViewport="true"
        fixedTopGap="60"
        [mode]="isLargeScreen() ? 'side' : 'over'"
        [opened]="sidenavOpen"
    >
        <div class="sidenav-header">
            <div class="flex justify-between items-center">
                <h1 class="page-title" data-automation="listTitle" [translate]="'APP.TITLE'"></h1>
                <button
                        mat-icon-button
                        color="accent"
                        aria-label="Menu"
                        class="toggle-sidenav flex justify-center items-center"
                        data-automation="toggle-sidenav"
                        (click)="sidenavOpen = !sidenavOpen"
                >
                    <mat-icon>double_arrow</mat-icon>
                </button>
            </div>
            <div class="toggle-geo-view flex justify-between inline-flex">
                <span class="toggle-geo-label">
                    {{ 'viewmode.geo' | translate }}</span>
                <mat-slide-toggle
                        aria-label="geoView"
                        color="accent"
                        class="flex justify-center items-center"
                        data-automation="toggle-geo-view"
                        [(ngModel)]="formModeChecked"
                        (change)="toggleGeoView()"
                >
                </mat-slide-toggle>
                <span class="toggle-form-label">
                    {{ 'viewmode.form' | translate }}</span>
            </div>
        </div>
        <div class="sidenav-component-container" data-automation="tree-container">
            <premo-tree></premo-tree>
        </div>
    </mat-sidenav>
    <mat-sidenav-content data-automation="sidenav-content" [class.sidenav-content-stretch]="!sidenavOpen">
        <div data-automation="scrollable-container" class="full overflow-auto">
            <div data-automation="main-container" class="main-container">
                <premo-loader></premo-loader>
                <premo-component class="main-container"></premo-component>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
