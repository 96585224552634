import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { DashboardService } from '@service';

@Injectable()
export class DashboardResolver  {
  constructor(private dashboardService: DashboardService) {}

  resolve(): Observable<string[]> {
    return this.dashboardService.fakeCall$();
  }
}
