import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SiteConfigureComponent } from 'src/app/view/site-configure/site-configure.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
  declarations: [SiteConfigureComponent],
  exports: [SiteConfigureComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
  ],
})
export class SiteConfigureModule {}
