import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatRippleModule} from '@angular/material/core';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {TranslateModule} from '@ngx-translate/core';
import {MapViewComponent} from 'src/app/shared/component/map-view/map-view.component';
import {AngularSplitModule} from "angular-split";
import {FormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {MatDialogModule} from "@angular/material/dialog";
import {ChartViewModule} from "src/app/shared/component/chart-view/chart-view.module";
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacyTooltipModule as MatTooltipModule} from "@angular/material/legacy-tooltip";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatTab} from "@angular/material/tabs";

@NgModule({
    declarations: [MapViewComponent],
    exports: [MapViewComponent],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        TranslateModule,
        MatRippleModule,
        MatTabsModule,
        AngularSplitModule,
        FormsModule,
        MatIconModule,
        MatDialogModule,
        ChartViewModule,
        MatTooltipModule,
        MatButtonModule,
    ],
})
export class MapViewModule {
}
