import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GlobalNavModule} from 'ngx-global-nav';
import {AppComponent} from './app.component';
import {AlertModule, LoaderModule, PremoTreeModule} from '@component';
import {CoreModule} from '@core';
import {PremoModule} from '@view';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {FormsModule} from '@angular/forms';

@NgModule({
    declarations: [AppComponent],
    imports: [
        AlertModule,
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        GlobalNavModule.forRoot({namespace: 'ngx-global-nav.'}),
        LoaderModule,
        MatSidenavModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        PremoModule,
        PremoTreeModule,
        MatSlideToggleModule,
        FormsModule
    ],
    exports: [BrowserModule, BrowserAnimationsModule, CoreModule],
    bootstrap: [AppComponent],
})
export class AppModule {
}
